<template>
  <div>
    <nav class="header-navbar w-100">
      <div
        class="navbar-header h-100"
        style="z-index: 1;"
      >
        <!-- Logo -->
        <b-link
          class="navbar-brand h-100"
          to="/"
        >
          <span class="brand-logo ml-2 pl-1 h-100">
            <b-img
              class="h-100 w-100"
              :src="require('@/assets/images/logo/TraceBit-logo.svg')"
              alt="Logo"
            />
          </span>
        </b-link>
        <div class="nav-right h-100 d-flex mr-2 pr-1">
          <b-link to="/login">
            <span>Log in</span>
          </b-link>
          <b-link to="/register">
            <span>Sign up</span>
          </b-link>
        </div>
      </div>
    </nav>
    <div class="app-content content ml-0 pb-2 text-justify">
      <b-card>
        <div class="p-2">
          <h2>Kebijakan Privasi TraceBit</h2>
          <br>
          <!-- Content Privacy Policy -->
          <p>Selamat datang di TraceBit,</p>
          <p>
            Kebijakan Privasi ini menjelaskan bagaimana TraceBit mengumpulkan dan menggunakan data atau informasi pribadi yang dikumpulkan melalui <i>Website</i> dan <i>Platform</i> serta menjelaskan hal-hal terkait penggunaan, akses dan cara memperbaiki atau memperbaharui data pribadi Pengguna.
          </p>
          <p>
            Kebijakan Privasi ini melengkapi namun tidak menggantikan izin-izin lain yang telah diberikan sebelumnya kepada TraceBit sehubungan dengan data atau informasi pribadi, dan izin Pengguna dalam konteks ini dianggap sebagai tambahan bagi hak-hak yang sebelumnya sudah tersedia bagi TraceBit untuk mengumpulkan, menggunakan dan membuka informasi pribadi Pengguna. Pengguna dimohon untuk membaca Kebijakan Privasi ini dengan seksama dan secara menyeluruh. Jika Pengguna tidak setuju dengan salah satu, sebagian, atau seluruh Kebijakan Privasi ini, Pengguna berhak untuk tidak mengakses dan/atau tidak menggunakan layanan TraceBit.
          </p>
          <ol type="1">
            <li>Informasi yang Kami Kumpulkan
              <ol type="a">
                <li>Ketika Pengguna mendaftar dan membuat akun di situs TraceBit, Pengguna harus memberikan data atau informasi pribadi tertentu, seperti nama, jenis kelamin, tanggal lahir, alamat email, nomor telepon, informasi pekerjaan, dan/atau kata sandi yang akan Pengguna gunakan untuk mengakses situs setelah pendaftaran;</li>
                <li>Setiap kali Pengguna menggunakan layanan  atau mengunjungi situs TraceBit, TraceBit dapat mengumpulkan data teknis tertentu sehubungan dengan penggunaan seperti, alamat protokol internet, informasi halaman web yang sebelumnya atau selanjutnya dilihat, durasi setiap kunjungan/sesi, identitas perangkat internet atau alamat kontrol akses media, dan informasi mengenai produsen, model, dan sistem operasi dari perangkat yang Pengguna gunakan untuk mengakses situs TraceBit;</li>
                <li>Data atau Informasi yang tertera dalam <i>poin b</i> juga dapat dikumpulkan secara otomatis dengan menggunakan <i>cookies</i>.</li>
              </ol>
            </li>
            <li>Penggunaan Cookies
              <ol type="a">
                <li><i>Cookies</i> merupakan file teks berisi potongan kecil data yang tersimpan di komputer untuk membantu menyimpan preferensi dan informasi lain yang digunakan, serta rekam jejak di halaman web yang dikunjungi Pengguna. Namun, dalam beberapa kasus, <i>cookie</i> hanya berisi informasi tentang pengaturan tertentu yang tidak dapat dikaitkan dengan orang tertentu. TraceBit menggunakan <i>cookie</i> di situs TraceBit;</li>
                <li>TraceBit menggunakan <i>cookie</i> untuk melacak aktivitas Pengguna dengan tujuan untuk meningkatkan pelayanan dan kepuasan Pengguna dalam memakai layanan TraceBit;</li>
                <li>Dalam hal-hal tertentu <i>cookie</i> dapat berdampak pada masalah <i>login</i> ke akun TraceBit Pengguna, apabila hal ini terjadi Pengguna dapat menghapus <i>cache</i> dan <i>cookie</i> yang disimpan di <i>browser</i> Pengguna;</li>
                <li>Pengguna dapat menonaktifkan <i>cookie</i> melalui pengaturan <i>browser</i> Pengguna, namun hal tersebut mungkin dapat berdampak pada tidak dapat diaksesnya beberapa fitur di situs TraceBit.</li>
              </ol>
            </li>
            <li>
              Penggunaan Informasi
              <br>
              TraceBit dapat mengumpulkan, menggunakan dan mengakses informasi pribadi Pengguna yang telah diperoleh untuk tujuan-tujuan sebagai berikut:
              <ol type="a">
                <li>Memproses dan menangani pertanyaan, memberikan tanggapan atas keluhan, masukan, dan permintaan Pengguna;</li>
                <li>Mencocokkan/ melakukan verifikasi data pribadi Pengguna yang telah ada sebelumnya untuk tujuan apapun yang tertulis disini;</li>
                <li>Meminta masukan atau partisipasi dalam survei, termasuk riset pasar;</li>
                <li>Mengirimkan email pemasaran, penawaran promosi, maupun voucher kepada Pengguna terkait fitur atau jasa serupa dari situs TraceBit;</li>
                <li>Memberikan informasi mengenai segala pembaruan pada situs TraceBit atau perubahan pada layanan yang disediakan;</li>
                <li>Menawarkan atau menyediakan layanan dari afiliasi atau mitra TraceBit;</li>
                <li>Menganalisis dan/atau menggabungkan informasi pribadi yang dimiliki oleh TraceBit dari waktu ke waktu untuk kebutuhan analisis data statistik, penyusunan profil, riset produk, desain produk dan pengembangan produk, memahami kebutuhan dan preferensi pelanggan, mempelajari tren dan permintaan pasar, serta perbaikan kualitas fitur produk dan layanan TraceBit;</li>
                <li>Memantau, mendeteksi dan mencegah penggunaan, atau penyalahgunaan layanan oleh pihak yang tidak sah;</li>
                <li>Menaati hukum dan peraturan perundang-undangan yang berlaku, atau untuk membantu penegakan hukum dan investigasi oleh Pihak-pihak yang berwenang; dan/atau</li>
                <li>Tujuan-tujuan lain sehubungan dengan hal-hal di atas.</li>
              </ol>
            </li>
            <li>
              Pemberian dan Pengungkapan Informasi
              <br>
              Setelah Pengguna mendaftar dan memberikan data atau informasi pribadi di situs TraceBit, TraceBit tidak akan menjual, mengalihkan, mendistribusikan dan/atau membuka informasi dan data-data pribadi Pengguna kepada orang lain dan/atau pihak ketiga yang tidak berkepentingan tanpa izin Pengguna, namun TraceBit dapat mengungkapkan atau memberikan data atau informasi pribadi Pengguna dengan afiliasi dan pihak lain untuk tujuan sebagai berikut:
              <ol type="a">
                <li>Pemberian tugas kepada mitra TraceBit atau pihak ketiga untuk membantu dalam proses peningkatan layanan TraceBit;</li>
                <li>Diperlukan dan/atau diperintahkan oleh Pengadilan dan/atau pejabat suatu instansi pemerintah Indonesia berdasarkan wewenang sah yang diberikan oleh ketentuan perundang-undangan;</li>
                <li>Tujuan lain yang diizinkan oleh peraturan perundang-undangan yang berlaku.</li>
              </ol>
            </li>
            <li>Pengamanan
              <ol type="a">
                <li>TraceBit akan berupaya semaksimal mungkin untuk melindungi data atau informasi pribadi Pengguna yang diterima, simpan atau berada di dalam kontrol TraceBit dengan menjalankan sistem pengamanan untuk mencegah akses dari pihak-pihak yang tidak berwenang dalam mengumpulkan, menggunakan, membuka, menyalin, memodifikasi, menghapus data atau risiko-risiko lain yang serupa dengan standar yang wajar. Namun demikian TraceBit tidak dapat memberikan garansi penuh atas keamanan data dari ancaman kode berbahaya ataupun tindakan kriminal lain yang menyerang situs TraceBit. Untuk melindungi data Pengguna dari hal-hal tersebut di atas, TraceBit dan Pengguna akan secara komprehensif mengambil langkah-langkah teknis dan organisasional;</li>
                <li>TraceBit akan mematuhi peraturan perundang-undangan yang berkaitan dengan perlindungan data di Indonesia untuk melindungi data atau informasi pribadi Pengguna serta turut melawan manipulasi data, kehilangan data, perusakan data, atau akses oleh orang yang tidak berwenang;</li>
                <li>Untuk melindungi keamanan data Pengguna selama transmisi, TraceBit menggunakan prosedur enkripsi SSL (<i>Secure Socket Layer</i>) saat mengirimkan Informasi Rahasia;</li>
                <li>Penggunaan enkripsi dapat dikenali melalui tampilan pada baris browser yang berubah dari <i>http://</i> menjadi <i>https://</i>;</li>
                <li>Dalam hal Pengamanan data, Pengguna juga wajib turut serta menjaga keamanan data atau informasi pribadi ini seperti halnya mengambil tindakan pencegahan untuk melindungi data Pengguna termasuk di dalamnya pemberian akses kepada pihak ketiga; TraceBit juga melakukan enkripsi data sensitif yang tersimpan agar tidak dapat dibaca oleh pihak ketiga;</li>
                <li>TraceBit tidak bertanggung jawab atas kerugian yang ditimbulkan oleh kelalaian, pelanggaran keamanan atau terhadap penggunaan tidak sah atas akses akun maupun informasi lainnya dari Pengguna;</li>
                <li>Apabila Pengguna menemukan kejanggalan akses akun, pencurian akun, atau hal-hal lain yang dianggap berbahaya untuk keamanan akun dan data Pengguna, Pengguna wajib menghubungi TraceBit dengan mengirimkan email ke support@tracebit.net.</li>
              </ol>
            </li>
            <li>Alat Analisis dan <i>Tools</i> Pihak Ketiga
              <ol type="a">
                <li>TraceBit dapat mempekerjakan mitra, perusahaan dan individu pihak ketiga atau menggunakan <i>tools</i> milik pihak ketiga tersebut untuk melakukan layanan terkait fitur TraceBit dan membantu dalam menganalisis layanan situs TraceBit. <i>Tools</i> atau Pihak Ketiga tersebut akan memiliki akses ke data atau informasi pribadi Pengguna semata-mata untuk melakukan tugasnya dan berkewajiban untuk tidak mengungkapkan atau menggunakannya untuk tujuan lain tanpa izin dan bertanggung jawab atas keseluruhan proses tersebut berdasarkan hukum yang berlaku.</li>
                <li>Apabila terjadi pengungkapan dan penggunaan data atau informasi Pengguna oleh Pihak Ketiga dengan melanggar kesepakatan atau hukum yang berlaku maka hal tersebut akan menjadi tanggung jawab Pihak Ketiga;</li>
                <li>Beberapa Alat Analisis dan <i>Tools</i> Pihak Ketiga yang TraceBit gunakan diantaranya (namun tidak terbatas pada):
                  <ol type="1">
                    <li>
                      <p>Google Analytics & Google Remarketing</p>
                      <p>Untuk mempelajari Kebijakan Privasi Google dapat diakses di halaman ini <a href="https://www.google.com/policies/privacy">https://www.google.com/policies/privacy</a> .</p>
                      <p>Untuk mengetahui informasi lebih lanjut mengenai penggunaan data untuk tujuan pemasaran oleh Google, Pengguna dapat mengunjungi halaman <a href="https://www.google.com/policies/technologies/ads">https://www.google.com/policies/technologies/ads</a>.</p>
                      <p>Apabila Pengguna ingin menolak pengumpulan data dari layanan pemasaran Google, Pengguna dapat menggunakan setelan dan opsi opt-out/penyisihan yang disediakan oleh Google di <a href="http://www.google.com/ads/preferences">http://www.google.com/ads/preferences</a>;</p>
                    </li>
                    <li>
                      <p>Facebook Remarketing</p>
                      <p>Untuk mengetahui informasi lebih lanjut mengenai Kebijakan Privasi Facebook, Pengguna dapat mengunjungi  halaman <a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>.</p>
                      <p>Untuk layanan Facebook Remarketing, Pengguna dapat menonaktifkan fungsi remarketing <i>Custom Audience</i> di bagian “Setelan Iklan” dengan masuk ke akun Facebook Pengguna terlebih dahulu.</p>
                    </li>
                    <li>
                      <p>Facebook Pixel</p>
                      <p>Untuk layanan Facebook Pixel, Pengguna dapat mempelajari informasi lebih lanjut mengenai cara kerja piksel <i>remarketing</i> serta tampilan iklan Facebook secara umum di kebijakan penggunaan data Facebook di halaman ini <a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a>;</p>
                    </li>
                    <li>
                      <p>Hotjar</p>
                      <p>Untuk mengetahui informasi lebih lanjut mengenai Kebijakan Privasi Hotjar, Pengguna dapat mengunjungi  halaman <a href="https://www.hotjar.com/privacy">https://www.hotjar.com/privacy</a>.</p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>Penyimpanan Informasi Pribadi
              <ol type="a">
                <li>Demi keamanan data atau informasi pribadi Pengguna, TraceBit akan memproses dan menyimpan informasi Pengguna pada server sesuai dengan ketentuan hukum yang berlaku;</li>
                <li>Data atau informasi pribadi Pengguna hanya akan disimpan selama diperlukan untuk memenuhi tujuan dari pengumpulannya, atau selama penyimpanan tersebut diperlukan atau diperbolehkan oleh peraturan perundang-undangan yang berlaku.</li>
              </ol>
            </li>
            <li>
              Pembaruan Kebijakan Privasi
              <br>
              Kebijakan Privasi ini dapat diubah atau diperbaharui sewaktu-waktu tanpa ada pemberitahuan terlebih dahulu. Perubahan dari Kebijakan Privasi ini akan segera berlaku setelah dicantumkan di dalam situs TraceBit. Dengan tetap menggunakan layanan setelah adanya Pembaruan Kebijakan Privasi,  maka Pengguna dianggap menerima dan memberikan persetujuan kepada TraceBit untuk melakukan pembaruan.
            </li>
            <li>
              Pengakuan dan Persetujuan
              <br>
              Dengan mengakses dan/atau menggunakan layanan di situs TraceBit, Pengguna dianggap memahami dan setuju untuk tunduk terhadap Kebijakan Privasi ini.  Pengguna juga dianggap memahami dan memberikan persetujuan kepada TraceBit untuk mengumpulkan, menggunakan, membagikan, mengungkapkan, menyimpan, mentransfer, atau mengolah data atau informasi pribadi Pengguna sesuai dengan Kebijakan Privasi ini.
            </li>
            <li>
              Informasi Pelanggan
              <br>
              Apabila Pengguna memiliki pertanyaan mengenai Kebijakan Privasi TraceBit, atau ingin mendapatkan akses, dan/atau ingin melakukan koreksi terhadap data atau informasi pribadi Pengguna, silakan hubungi <a href="support@tracebit.net">support@tracebit.net</a>.
            </li>
            <li>
              Yurisdiksi Hukum
              <br>
              Kebijakan Privasi ini diatur dan ditafsirkan berdasarkan hukum negara Republik Indonesia.
            </li>
          </ol>
          <p>Pembaruan terakhir: 1 Oktober 2022</p>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BLink, BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
    BCard,
  },
}
</script>

<style>
  .navbar .navbar-menu {
    margin: 0 !important;
    max-height: 100%;
    min-width: 0;
    width: 100% !important;
    z-index: 1 !important;
  }
  .header-navbar {
    position: fixed;
    height: 4.45rem;
    background-color: white;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  }
  .navbar-brand {
    padding: 0.5rem 0;
  }
  .brand-logo img {
    max-height: 30px;
    max-width: 100% !important;
  }
  .nav-right {
    float: right;
  }
  .nav-right a {
    display: flex;
    align-items: center;
    padding: 0rem 16px;
    color: #505050;
    font-size: 1.2rem;
  }
  .nav-right a:hover {
    color: #1b4694;
  }
</style>
